import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { SwEaseAPI } from './SwEaseAPI';
/* eslint-disable @backstage/no-forbidden-package-imports */
import { Answer } from '@roche-ease/plugin-sw-ease-backend';
import {
  AnswerRequest,
  GetAnswerRequest,
  QuestionResponse,
  UserOrgProductResponseBody,
} from './response/Types';
import { SwEaseFetchAPI } from './SwEaseFetchAPI';

const PARAM = {
  YAML_QUESTION_ID: ':yaml_question_id',
};

const API = {
  ORG_PRODUCT_GET: '/org_product',
  QUESTION_GET: `/question/${PARAM.YAML_QUESTION_ID}`,
  ANSWER_POST: '/answer',
  ANSWER_GET: `/answer/all`,
};

export const swEaseApiRef = createApiRef<SwEaseAPI>({
  id: 'plugin.sw-ease.api',
});

export class SwEaseClient implements SwEaseAPI {
  private readonly fetchApi: SwEaseFetchAPI;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.fetchApi = new SwEaseFetchAPI(
      'sw-ease-backend',
      options.discoveryApi,
      options.fetchApi,
    );
  }

  async getQuestion(yamlQuestionId: string): Promise<QuestionResponse> {
    const api = API.QUESTION_GET.replace(
      PARAM.YAML_QUESTION_ID,
      yamlQuestionId,
    );

    return this.fetchApi.fetch(
      api,
      `Failed to get question with yamlQuestionId: ${yamlQuestionId}.`,
    );
  }

  async saveAnswer(answer: AnswerRequest): Promise<Answer> {
    return this.fetchApi.fetch(
      API.ANSWER_POST,
      `Failed to save answer for question: ${answer.yaml_question_id}.`,
      answer,
    );
  }

  async getUserOrgProduct(): Promise<UserOrgProductResponseBody> {
    return this.fetchApi.fetch(
      API.ORG_PRODUCT_GET,
      `Failed to get user org product.`,
    );
  }

  async getAnswersByGroup(question: GetAnswerRequest): Promise<Answer[]> {
    return this.fetchApi.fetch(
      API.ANSWER_GET,
      `Failed to get answer for question ${question.yaml_question_ids} based on user session`,
      question,
    );
  }
}
